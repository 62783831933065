<template>
    <layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Edit Training</h4>
                        <form >
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label > Name</label>
                                        <input
                                                type="text"
                                                placeholder="Name"
                                                class="form-control"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label >Region</label>
                                        <multiselect v-model="value" :options="options" :multiple="false" placeholder="Select Region"></multiselect>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label >City</label>
                                        <input
                                                type="text"
                                                placeholder="City"
                                                class="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Institution</label>
                                        <input
                                                type="text"
                                                placeholder="Institution"
                                                class="form-control"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label> Responsibility</label>
                                    <input
                                            type="text"
                                            placeholder="Responsibility"
                                            class="form-control"
                                    />

                                </div>
                                <div class="col-md-4">
                                    <label> Phone number</label>
                                    <input
                                            type="number"
                                            placeholder="Phone number"
                                            class="form-control"
                                    />
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Email address</label>
                                        <input
                                                type="email"
                                                placeholder="Email address"
                                                class="form-control"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label> Training period</label>
                                    <date-picker v-model="daterange" range append-to-body lang="en" confirm></date-picker>

                                </div>

                            </div>
                            <button class="btn btn-primary" type="submit">Edit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
    import Layout from "../../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Multiselect from "vue-multiselect";
    import DatePicker from "vue2-datepicker";

    export default {
        page: {
            title: "Edit Training",
            meta: [{ name: "description", content: appConfig.description }]
        },
        components: {
            Layout, DatePicker,
            Multiselect,
            PageHeader },
        data() {
            return {
                title: "Edit Training",
                items: [
                    {
                        text: "CQI",
                        href: "/"
                    },
                    {
                        text: "Edit training",
                        active: true
                    }
                ],

                options: [
                    "Adamawa ",
                    "Centre",
                    "East",
                    "Far North",
                    "Littoral",
                    "North",
                    "North West",
                    "West",
                    "South",
                    "South West",
                ],
                daterange: "",


                value:null,
                value1: null,
            };

        },
        validations: {

        },
        methods: {

        }
    };
</script>

<style scoped>

</style>